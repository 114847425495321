import CountryCode from '@root/interfaces/CountryCode';

import { ASSETS_URL } from '@services/api/v1/constants/cdnAndOthers';

// eslint-disable-next-line import/prefer-default-export
export const getCountryBackgroundImgSrc = (
  countryCode: CountryCode,
  variantIndex = 0,
) => {
  return `${ASSETS_URL.COUNTRY_IMAGES}/${countryCode}/${variantIndex}.jpg`;
};
